
import React, { Fragment, useState, useEffect } from "react";

import {
    TablePagination,
    TableContainer,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
    Table,
    Paper,
    Box,
    CircularProgress,
    TextField,
    Toolbar,
    InputBase,
    Grid,
  TableSortLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Tooltip
} from "@material-ui/core";
import { alpha, makeStyles } from '@material-ui/core/styles';
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { useStyles } from "./../../styles/style";
import { Link } from 'react-router-dom';
import DeleteModal from './../Reusable/deleteModal';
import axios from 'axios';
import cogoToast from 'cogo-toast';
import SearchIcon from '@material-ui/icons/Search';
import { Publish } from "@material-ui/icons";
import { content_type } from '../../Pages/bucket/objects'
import { game_content_type } from '../../Pages/bucket/objects'



export default function ListPlayList(props) {

    const classes = useStyles();
    // const inClasses = cssStyles()
    const [pagination, setPagination] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState([]);
    const [deleteId, setDeleteId] = useState("")
    const [searched, setSearched] = useState("");
    const [deleteModal, setDeleteModal] = useState(false)
    const toggle = () => setDeleteModal(!deleteModal);
    const [id, setId] = useState("")
    const [name, setName] = useState("")
    const [content, setContent] = useState("")
    const [langList, setLangList] = useState([])
    const [lang, setLang] = useState("");
    const fetchPlaylist = async () => {
        await axios.get(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/system/playlist`)
            .then(

                res => {
                    console.log('==>', res)
                    setData(res.data.result)
                }
            ).catch(
                err => {
                    console.log(err);
                }
            )
    }

    const onClear = () => {
        fetchPlaylist()
        setId("");
        setName("");
        setContent("");
        setLang("")
      }

      const fetchLanguage = async () => {
        await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/type/language?storeId=${localStorage.getItem("storeId")}`).then(res => {
          setLangList(res.data.result.result)
        }).catch(
          err => {
            console.log(err)
          }
        )
      }
    const deleteBucket = async (id) => {
        await axios.delete(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/system/playlist/${deleteId}`).then(
          res => {
            console.log(res);
            let newData = data.filter(ele => {
              return ele.id !== deleteId;
            });
            setData(newData);
            setDeleteId("")
          }
        ).catch(err => {
          console.log(err);
        })
      }

    useEffect(() => {
        fetchPlaylist()
        fetchLanguage()
    }, [])

    useEffect(() => {
        if (searched === "") {
          fetchPlaylist()
        }
    }, [searched])

    const searchContent = (e) => {
        searchBucket(id,name,lang, content)
    }
    const searchBucket = async (id,name,lang, content) => {
        await axios.get(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/system/playlist/search?name=${name}&id=${id}&lang=${lang}&storeId=${localStorage.getItem("storeId")}`)
          .then(
            res => {
              let data = res.data.result.length>0 ?res.data.result :[];
            //   let object = data.filter(ele => {
            //     if(typeof(ele.names) === "string"){
            //       ele.names = JSON.parse(ele.names)
            //       return ele
            //     }else{
            //       return ele
            //     }
                
            //   })
              setData(data)
            //   setIsLoading(false)
              console.log(res.data.result);
            //   cogoToast.success(res.data.result)
            }
          ).catch(
            err => {
              console.log(err);
              setIsLoading(false)
            }
          )
      }
    const searchTopNav = async (data) => {
        await axios.get(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/system/playlist/search/${data}?storeId=${localStorage.getItem("storeId")}`)
            .then(res => {
                console.log('search response',res);
                let object  = res.data.result.filter(ele => {
                    return !ele.parentId
                })
                setData(object)
                setIsLoading(false)
    
            }).catch(err => {
                console.log(err)
            })
    }
    const handleChangePage = (event, newPage) => {
        setPagination(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPagination(0);
    };
    return (
        <Fragment>
            <Paper component={Box} p={4}>
                <Toolbar>
                    <h1>List Playlist</h1>
                    {/* <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Search…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                            value={searched}
                            onChange={(e) => setSearched(e.target.value)}
                            onKeyDown={(e) => searchContent(e)}
                        />
                    </div> */}

                </Toolbar>
                <Grid container className="mt-3 mb-3">
          <Grid item md={3}>
            <TextField variant="outlined" value={id} onChange={(e)=>setId(e.target.value)} width="25"  label="Id" name="name"></TextField>
          </Grid >
          <Grid item md={3}>
            <TextField variant="outlined" value={name} onChange={(e)=>setName(e.target.value)} width="25"  label="name" name="name"></TextField>
          </Grid >

          <Grid item md={3}>
            <FormControl variant="outlined"  className={classes.formControl} style={{top:-8, marginLeft:"-4px"}}>
              <InputLabel id="demo-simple-select-outlined-label">Language</InputLabel>

              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={lang}
                onChange={(e)=>setLang(e.target.value)}
                label="Language"
              ><MenuItem value="Default">Default</MenuItem>
                {
                  langList && langList.length > 0 ?
                    langList.map((element, id) => {

                      return (
                        <MenuItem key={id} value={element.new_code}>{element.name}</MenuItem>
                      )
                    }) :
                    <MenuItem disabled value="no pages">No Pages</MenuItem>

                }

              </Select>

            </FormControl>

          </Grid>
          <Grid item md={3}>
          <Button color="primary" variant="contained" onClick={searchContent} className="mt-2">Search</Button>{" "}
          <Button color="secondary" variant="contained" onClick={onClear}  className="mt-2">Clear</Button>
          </Grid>
        </Grid>

                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Language</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Rights</TableCell>
                                <TableCell>Last Updated On</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading == true ? <CircularProgress /> :
                                data && data.length > 0 ? data
                                    .slice(pagination * rowsPerPage, (pagination + 1) * rowsPerPage)
                                    .map((emp, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{emp.id}</TableCell>
                                            <TableCell>{emp.title}</TableCell>
                                            <TableCell>{emp.language_id}</TableCell>
                                            <TableCell >{emp.status === 1 ? (

                                                <span
                                                    style={{
                                                        height: '10px',
                                                        width: '10px',
                                                        backgroundColor: 'green',
                                                        borderRadius: '50%',
                                                        display: 'inline-block',
                                                        marginRight: '5px',
                                                    }}
                                                ></span>
                                            ) : (
                                                <span
                                                    style={{
                                                        height: '10px',
                                                        width: '10px',
                                                        backgroundColor: 'red',
                                                        borderRadius: '50%',
                                                        display: 'inline-block',
                                                        marginRight: '5px',
                                                    }}
                                                ></span>
                                            )}</TableCell>
                                            <TableCell>
                                                {emp.licence_status === 1 ? (
                                                    <span>✔️</span>
                                                ) : emp.licence_status === 0 ? (
                                                    <span>❌</span>
                                                ) : <span>❌</span>}
                                            </TableCell>
                                            <TableCell>{emp.update_date}</TableCell>
                                            <TableCell>
                                                <Link to={`/editPlaylist/${emp.id}`}><EditOutlinedIcon className={classes.btnedit} /></Link>
                                                <DeleteForeverOutlinedIcon
                                                    className={classes.btndelete}
                                                    onClick={() => { setDeleteId(emp.id); toggle() }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )) : <p className="mt-2 text-center">no data</p>}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15, 50, 100]}
                        component="div"
                        count={data ? data.length : null}
                        rowsPerPage={rowsPerPage}
                        page={pagination}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </TableContainer>
                <DeleteModal
          toggle={toggle}
          deleteModal={deleteModal}
          deleteContent={deleteBucket}
        />
            </Paper>
        </Fragment>
    );

}
