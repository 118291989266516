import React, { useState, Fragment,useEffect,useRef } from 'react'
import {
    Grid,
    TextField,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Button,
    Paper,
    Box,
    Checkbox,
    Card,
    CardHeader,
    Select,
    InputLabel,
    ListItemIcon,
    MenuItem,
    IconButton,
    Divider,
    InputBase,
    List,
    ListItemText,
    ListItem,
} from '@material-ui/core';
import cogoToast from 'cogo-toast';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import NoImage from './../../images/no_image.png'
import SearchIcon from '@material-ui/icons/Search';
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import { useStyles } from '../../styles/style';
import axios from 'axios';
import BucketQuery from '../bucket/bucketQuery';
import DateFnsUtils from "@date-io/date-fns";
import { getYear } from 'date-fns';
import {
    DateTimePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import CancelIcon from '@material-ui/icons/Cancel';
import { content_type,game_content_type, tags, operators, fields, name as radioList} from '../bucket/objects';

export default function EditPlayList(props) {
    const classes = useStyles();
    const textRef = useRef();
    const [category, setCategory] = useState("");
    const [publicStatus, setPublicStatus] = useState("true");
    const [publishedStatus, setPublishedStatus] = useState("true");
    const [liveDate, setLiveDate] = useState(new Date());
    const [expiryDate, setExpiryDate] = useState(new Date());
    const [year, setYear] = useState(getYear(new Date()));
    const [year2, setYear2] = useState(getYear(new Date()));
    const [select, setSelect] = useState([]);
    const [searched, setSearched] = useState("")
    const [name, setName] = useState();
    const [genres, setGenres] = useState();
    const [name2, setName2] = useState();
    const [actors, setActors] = useState();
    const [singers, setSingers] = useState();
    const [stencil, setStencil] = useState("");
    const [background, setBackground] = useState("");
    const [v3, setV3] = useState("");
    const [langList, setLangList] = useState([{ code: "Default", name: "Default" }])
    const [lang, setLang] = useState("Default")
    const [queryResult, setQueryResult] = useState([]);
    const [option1, setOption1] = useState(fields[0]);
    const [option2, setOption2] = useState("Equal");
    const [option3, setOption3] = useState("");
    const [text, setText] = useState("");
    const [query, setQuery] = useState([]);
    const [andOr, setAndOr] = useState([]);
    const queryContentType = content_type.filter(ele=>{
        return ele.Description != "charts"
    })
    const [checked, setChecked] = React.useState([]);
    const [content, setContent] = useState("")
    const [leftData, setLeftData] = useState([]);
    const [deletev0, setDeletev0] = useState([]);
    const [deletev2, setDeletev2] = useState([]);
    const [deletev3, setDeletev3] = useState([]);
    const leftChecked = intersection(checked, leftData);
    const rightChecked = intersection(checked, queryResult);

    const handlePublic = (e) => {
        setPublicStatus(e.target.value);
    }

    const optionOneHandleChange = (e) => {
        setOption1(e.target.value);
        setText("");

    }
    function union(a, b) {
        return [...a, ...not(b, a)];
    }
    const varientArray = ["none", "v1", "v2", "v3", "v4", "v5", "v6"]
    const optionTwoHandleChange = (e) => {
        setOption2(e.target.value)
    }
    const deleteLeftData =() =>{
        setLeftData(not(leftData, leftChecked));
        setChecked(not(checked, leftChecked));
    }
    const optionThreeHandleChange = (e) => {
        setOption3(e.target.value);
        setText(e.target.value);
    }
    const onTextChange = (e) => {
        setText(e.target.value);
    }
    const handleVarintChange = (e, index) => {
        leftData.map((ele, i) => {

            if (i === index) {
                ele.defaultVarient = e.target.value
            }

        })
    }
    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };
    const handleDateChange = (date) => {
        setLiveDate(date);
        setYear(getYear(date)); // Extract year using date-fns
      };
      const handleDateChange2 = (date) => {
        setExpiryDate(date);
        setYear2(getYear(date)); // Extract year using date-fns
      };
    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };
    const numberOfChecked = (items) => intersection(checked, items).length;

    const postQuery = async (data,arrays) => {
        await axios.post(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/query`, data)
            .then(
                res => {
                    let array = [];
                    console.log('-',res.data.length)
                    if (res.data.length > 0) {
                        const data = res.data
                        console.log('res_data',res.data);
                        const newResultData = data.map((ele, i) => {
                            if((ele.data.id).includes('playlist-')){
                            array.push((ele.data.id).replace('playlist-',''));
                            }
                            else if((ele.data.id).includes('artist-')){
                                array.push((ele.data.id).replace('artist-',''));
                            }
                            else{
                                array.push((ele.data.id)) 
                            }
                            let obj = {
                                id: ele.data.id,
                                image: ele.data.image,
                                title: ele.data.title,
                                varient: varientArray,
                                defaultVarient: ""
                            }
                            return obj
                        })
                        let filterObj = newResultData.filter(qId => !leftData.some(o => o.id === qId.id))

                        setQueryResult(filterObj)
                        // for(let i in arrays){
                        //     for(let j in res.data){
                        //         if((res.data[j].data.id).includes(arrays[i])){
                        //             console.log('>>>>')
                        //         }
                        //         else{
                        //             array.push(arrays[i]);
                        //             console.log('<<<<',`${arrays[i]},No data Found`)
                        //         }
                        //     }
                        // }
                        console.log('--',arrays)
                        console.log('--',array)
                        let NewArray = arrays.filter((x) => !array.includes(x))
                        if(NewArray.length > 0){
                            cogoToast.error(`${NewArray} No data Found`);
                            }
                    }
                    else{
                        cogoToast.error("No data Found");
                    }
                }
            ).catch(err => {
                console.log(err.response)
                cogoToast.error("Bad Request Solr Error")
                setQueryResult([])
            })

    }
    function not(a, b) {
        return a.filter((value) => b.indexOf(value) === -1);
    }
    function intersection(a, b) {
        return a.filter((value) => b.indexOf(value) !== -1);
    }
    const onAndOrChange = (e, index) => {
        const values = [...andOr];
        values[index].AND_OR = e.target.value;
        setAndOr(values)
        let data = query;
        data[index]['AND_OR'] = e.target.value

        setQuery(data)
    }
    const ondeleteQuery = (e) => {
        let filtervalue = query.filter((ele, i) => {
            if (e === i) {
                return i !== e
            } else {
                return ele
            }
        })
        setQuery(filtervalue)
        if (query.length <= 1) {
            setAndOr("")
        }
    }

    const onSerachQuery = () => {
        console.log(query)
        let array;
        const obj = query.map(ele => {
            let newQuery = {
                "AND_OR": ele.AND_OR,
                "field": ele.field.value,
                "operators": ele.operators.toString().replace(' ', '_').toLowerCase(),
                "text": ele.text.value ? ele.text.Description : ele.text.Description ? ele.text.typeid : ele.text

            }
            return newQuery
        })
        let newObj = {
            'query': obj,
            "storeId":localStorage.getItem("storeId"),
            "type":content
        }
        
        array = (query[1].text).split(',')
        console.log('----',array);
        console.log(newObj)
        postQuery(newObj,array);
    }
    const handleCheckedRight = () => {
        setQueryResult(queryResult.concat(leftChecked));
        setLeftData(not(leftData, leftChecked));
        setChecked(not(checked, leftChecked));
    };
    const handleCheckedLeft = () => {
        setLeftData(leftData.concat(rightChecked));
        setQueryResult(not(queryResult, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const left = (title, items) => (
        <Card >
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items.length === 0}
                        inputProps={{ 'aria-label': 'all items selected' }}
                    />
                    
                }
                action={
                    <IconButton aria-label="delete" onClick={deleteLeftData} disabled={leftChecked.length === 0}>
                        <DeleteForeverOutlinedIcon className={leftChecked.length === 0?null:classes.btndelete}/>
                    </IconButton>
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="contents" >
                    {(provided) => (
                        <List className={classes.list} dense component="div" role="list" {...provided.droppableProps} ref={provided.innerRef}>
                            {items.map((value, index) => {
                                const labelId = `${value.id}`;

                                return (
                                    <Draggable key={value.id} draggableId={value.id} index={index}>
                                        {(provided) => (
                                            <ListItem role="listitem" button onClick={handleToggle(value)} {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        checked={checked.indexOf(value) !== -1}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </ListItemIcon>
                                                <img src={value.image ? value.image : NoImage} height="50" width="50" />

                                                <ListItemText id={labelId}>
                                                    <Grid container>
                                                        <Grid item md={6}>
                                                            <p className="mt-4 ml-1">{value.title}</p>
                                                        </Grid>
                                                        <Grid item md={6}>
                                                            <FormControl variant="outlined" className={classes.formControl} style={{ minWidth: "50%" }}>

                                                                <Select
                                                                    id="demo-simple-select-outlined"
                                                                    value={value.defaultVarient}
                                                                    onChange={(e) => handleVarintChange(e, index)}
                                                                >{value.varient.map(ele => (
                                                                    <MenuItem value={ele}>{ele}</MenuItem>

                                                                ))
                                                                    }


                                                                </Select>

                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>



                                                </ListItemText>


                                            </ListItem>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </List>
                    )}
                </Droppable>
            </DragDropContext>

        </Card>
    );
 const right = (title, items) => (
        (items && items.length > 0 ?
            <Card>
                <CardHeader
                    className={classes.cardHeader}
                    avatar={
                        <Checkbox
                            onClick={handleToggleAll(items)}
                            checked={numberOfChecked(items) === items.length && items.length !== 0}
                            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                            disabled={items.length === 0}
                            inputProps={{ 'aria-label': 'all items selected' }}
                        />
                    }
                    title={title}
                    subheader={`${numberOfChecked(items)}/${items.length} selected`}
                />

                <Divider />
                <div className={classes.contentSearch}>
                    <div className={classes.searchIcon}>
                        <SearchIcon />
                    </div>
                    <InputBase
                        placeholder="Search…"
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        value={searched}
                        onChange={(e) => setSearched(e.target.value)}
                        inputProps={{ 'aria-label': 'search' }}
                    />
                </div>


                <List className={classes.list} dense component="div" role="list">
                    {items.
                        filter(val => {
                            if (searched === "") {
                                return val
                            } else if (val.title.toLowerCase().includes(searched.toLowerCase())) {
                                return val
                            }
                        }).map((value) => {
                            const labelId = `${value._id}`;

                            return (
                                <ListItem key={value._id} role="listitem" button onClick={handleToggle(value)}>
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={checked.indexOf(value) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <img src={value.image ? value.image : NoImage} height="50" width="50" />


                                    <ListItemText id={labelId} primary={value.title} />
                                </ListItem>
                            );
                        })}
                    <ListItem />
                </List>
            </Card> :
            <Card>
                <CardHeader
                    className={classes.cardHeader}
                    avatar={
                        <Checkbox
                            inputProps={{ 'aria-label': 'all items selected' }}
                        />
                    }
                    title={title}
                    subheader="no data"
                />
                <Divider />

                <List className={classes.list} dense component="div" role="list">
                    <p className="text-center">no data</p>
                    <ListItem />
                </List>
            </Card>)

    );
    const handlePublished = (e) => {
        setPublishedStatus(e.target.value);
    }

    const handleCategory = (e) => {
        console.log('-->',e.target.value)
        setCategory(e.target.value)
    }

    const categories = [{
        id: 21,
        value: "Music"
    }
    // , {
    //     id: 3,
    //     value: "Video"
    // }, {
    //     id: 3,
    //     value: "podcast"
    // },{
    //     id: 4,
    //     value: "Charts"
    // },
]

// convert string to boolean
var str2bool = (value) => {
    if (value && typeof value === "string") {
        if (value.toLowerCase() === "true") return true;
        if (value.toLowerCase() === "false") return false;
    }
    return value;
}

const postApi = async (data) => {
    const fd = new FormData();
    fd.append("storeId",data.storeId);
    fd.append("sectionId", "82");
    fd.append("type", "playlist");
    fd.append("vod_type", "1");
    fd.append("content_type_id", "21");
    fd.append("app_type", "");
    fd.append("is_free", "0");
    fd.append("priority", "0");
    fd.append("plan", "");
    fd.append("p_users", "");
    fd.append("subcat_id", "");
    fd.append("name", data.name);
    fd.append("lang", data.lang);
    fd.append("era_from", data.live_date_time);
    fd.append("era_to", data.expiry);
    fd.append("public", data.public);
    fd.append("tag", data.tag);
    fd.append("geners", data.genres);
    fd.append("actors", data.actors);
    fd.append("singers", data.singers);
    fd.append("published", data.published);
    fd.append("query", JSON.stringify(data.query));
    fd.append("queryData", JSON.stringify(data.queryData));
    data.deletev0.map((ele, i) => {
        fd.append(`deletev0[${i}]`, ele)
    })
    data.deletev2.map((ele, i) => {
        fd.append(`deletev2[${i}]`, ele)
    })
    data.deletev3.map((ele, i) => {
        fd.append(`deletev3[${i}]`, ele)
    })
    for (let index = 0; index < data.stencil.length; index++) {
        const element = data.stencil[index];
        fd.append('v0', element)
    }
    for (let index = 0; index < data.background.length; index++) {
        const element = data.background[index];
        fd.append('v2', element)
    }
    for (let index = 0; index < data.v3.length; index++) {
        const element = data.v3[index];
        fd.append('v3', element)
    }
    console.log('fd',fd)
    await axios.put(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/system/playlist/${props.match.params.id}`, fd)
        .then(
            res => {
                console.log(res)
                cogoToast.success(res.data.message)
                    props.history.push("/listPlayList")
            }
        ).catch(err => {
            console.log(err);
            cogoToast.error('error');
        })

}
useEffect(() => {
    fetchLanguage();
    fetchPlaylistByID();
}, [])

const fetchLanguage = async () => {
    await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/type/language?storeId=${localStorage.getItem("storeId")}`).then(res => {
        setLangList([...langList, ...res.data.result.result])
        console.log('lang',res.data.result.result);
    }).catch(
        err => {
            console.log(err)
        }
    )
}

const fetchPlaylistByID = async () => {
    await axios.get(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/system/playlist/${props.match.params.id}`)
        .then(
            res => {
                console.log('response=>',res.data.result[0]);
                let Data = JSON.parse(res.data.result[0].data);
                console.log('data',Data);
                setName(res.data.result[0].title)
                setLang(res.data.result[0].language_id)
                setName2(Data.tags)
                setGenres(Data.tags)
                setActors(Data.tag_actors)
                setSingers(Data.tag_singers)
                setPublishedStatus(res.data.result[0].status == 1 ? "true" : "false")
                setYear(Data.tag_from_era)
                setYear2(Data.tag_to_era)
                setStencil(res.data.result[0].v0)
                setBackground(res.data.result[0].v2)
                setV3(res.data.result[0].v3)
                let QueryData = JSON.parse(res.data.result[0].queryData)

                let newQueryData = QueryData.map(ele => {
                    let obj = {
                        id: ele.id,
                        title: ele.title,
                        defaultVarient: ele.varient,
                        image: ele.image,
                        sequence: ele.sequence,
                        varient: varientArray
                    }
                    return obj

                })          
                setLeftData(newQueryData)
                let query = JSON.parse(res.data.result[0].query)
                setQuery(query)
                let and_or = query.map((ele,i)=>{
                    let abc = {
                        AND_OR:ele.AND_OR
                    }
                    return abc
                })
                setAndOr(and_or)
            }
        ).catch(
            err => {
                console.log(err)
            }
        )

}
const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(leftData);
    const [reorderItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderItem);

    setLeftData(items)


}
const onSubmitquery = () => {
    const values = [...andOr]
    values.push({ AND_OR: '' })
    setAndOr(values)
    setQuery([...query, { "AND_OR": "", "field": option1, "operators": option2, "text": text }]);


}
const onSubmit = () => {
    const QueryData = leftData.map((ele, i) => {
        let obj = {
            id: ele.id,
            title: ele.title,
            varient: ele.defaultVarient,
            image: ele.image,
            sequence: i
        }
        return obj;
    })
    let obj = {
        name:name,
        category:category,
        lang:lang,
        tag:name2,
        genres:genres,
        actors:actors,
        singers:singers,
        public:str2bool(publicStatus),
        published:str2bool(publishedStatus),
        "live_date_time": year,
        "expiry": year2,
        stencil:stencil,
        background:background,
        v3:v3,
        deletev0: deletev0,
        deletev2:deletev2,
        deletev3:deletev3,
        query:query,
        queryData:QueryData,
        storeId:localStorage.getItem("storeId")
    }
    console.log("obj",obj)
    postApi(obj)
    setName("")
    setName2("")
}

const deleteStencil = (e, i,ele) => {
    let newArray = stencil.filter((ele, index) => {
        return index !== i;
    })
    setDeletev0([...deletev0, ele]);
    setStencil(newArray)
}

const deleteBackground = (e, i,ele) => {
    let newArray = background.filter((ele, index) => {
        return index !== i;
    })
    setDeletev2([...deletev2, ele])
    setBackground(newArray)
}
const deleteV3 = (e, i,ele) => {
    let newArray = v3.filter((ele, index) => {
        return index !== i;
    })
    setDeletev3([...deletev3, ele])
    setV3(newArray)
}
    return (
        <Fragment>
            <Paper component={Box} p={4}>
                <div>
                    <h1 className="text-center mb-5" >Edit PlayList</h1>
                    <Grid container style={{marginLeft:"20px"}}>
                        <Grid item md={9} sm={12}>
                            <TextField InputLabelProps={{ shrink: true }}  style={{width:"40%"}} variant="outlined" className={classes.inputField} width="25" label="Name" value={name} onChange={(e)=>setName(e.target.value)}>
                            </TextField>
                        </Grid>
                        <Grid item md={12} sm={12}>
                            <Grid container>
                                <Grid item md={6} sm={12}>
                <FormControl style={{minWidth:"60%"}} variant="outlined" className={classes.formControl} >
                    <InputLabel id="demo-simple-select-outlined-label">Language</InputLabel>

                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={lang}
                        onChange={(e) => setLang(e.target.value)}
                        label="Language"
                    >
                        {
                            langList.length > 0 ?
                            langList.map((element, id) => {

                                    return (
                                        <MenuItem key={id} value={element.new_code}>{element.name}</MenuItem>
                                    )
                                }) :
                                <MenuItem disabled value="no Lamguage">No Lamguage</MenuItem>

                        }

                    </Select>

                </FormControl>

            </Grid>
            <Grid item md={6} sm={12}>
                            {/* <TextField style={{width:"60%"}} variant="outlined" className={classes.inputField} width="25" name="tags" label="Tags" value={name2} onChange={(e)=>setName2(e.target.value)}> */}
                            <TextField InputLabelProps={{ shrink: true }} variant="outlined" className={classes.inputField} width="25" required label="Tags" name="tags" value={name2} onChange={e => setName2(e.target.value)} >

                            </TextField>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField InputLabelProps={{ shrink: true }} style={{width:"60%"}} variant="outlined" className={classes.inputField} width="25" label="Tag Genres" value={genres} onChange={(e)=>setGenres(e.target.value)}>
                            </TextField>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField InputLabelProps={{ shrink: true }} style={{width:"60%"}} variant="outlined" className={classes.inputField} width="25" label="Tag Actors" value={actors} onChange={(e)=>setActors(e.target.value)}>
                            </TextField>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField InputLabelProps={{ shrink: true }} style={{width:"60%"}} variant="outlined" className={classes.inputField} width="25" label="Tag Singers" value={singers} onChange={(e)=>setSingers(e.target.value)}>
                            </TextField>
                        </Grid>
                            </Grid>
                            
                        </Grid>
                        {/* <Grid item md={6} sm={12}>
                            <FormControl className={classes.formControl} >
                                <FormLabel className={classes.lables} component="div" >Public</FormLabel>
                                <RadioGroup aria-label="enable" name="enable" value={publicStatus} onChange={handlePublic} row>

                                    <FormControlLabel value="true" control={<Radio />} label="true" />
                                    <FormControlLabel value="false" control={<Radio />} label="false" />

                                </RadioGroup>
                            </FormControl>

                        </Grid> */}
                        <Grid item md={6} sm={12}>
                            <FormControl className={classes.formControl}>
                                <FormLabel className={classes.lables} component="div" >Published</FormLabel>
                                <RadioGroup aria-label="enable" name="enable" value={publishedStatus} onChange={handlePublished} row>

                                    <FormControlLabel value="true" control={<Radio />} label="true" />
                                    <FormControlLabel value="false" control={<Radio />} label="false" />

                                </RadioGroup>
                            </FormControl>

                        </Grid>
                        <Grid item md={6} sm={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker label="Live Date time" views={['year']} openTo="year" className={classes.inputField} value={year} onChange={handleDateChange} ></DateTimePicker>
                                 <div>Selected Year: {year}</div>
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker label="Expiry Date time" views={['year']} openTo="year" className={classes.inputField} value={year2} onChange={handleDateChange2} ></DateTimePicker>
                                <div>Selected Year: {year2}</div>
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                                V0
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={(e)=>setStencil([...stencil,...e.target.files])}
                                />
                            </Button>
                            {stencil.length > 0 ?
                                // (stencil.map((ele, i) => <p className="mt-2 mr-4">{ele.name} <CancelIcon onClick={(e) => deleteStencil(e, i)} /></p>))
                                (stencil.map((ele, i) => <div><p className="mt-2 mr-4" onClick={() => window.open(ele.name ? null : `https://storage.googleapis.com/media-content-hungama-com/${ele}`, "_blank")} style={{ cursor: "pointer", display: "inline" }}>{ele.name ? ele.name : ele}</p><CancelIcon onClick={(e) => deleteStencil(e, i, ele)} /></div>))
                                : null}
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"
                            >
                                V2
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={(e)=>setBackground([...background,...e.target.files])}
                                />
                            </Button>
                            {background.length > 0 ?
                                // (background.map((ele, i) => <p className="mt-2 mr-4">{ele.name} <CancelIcon onClick={(e) => deleteBackground(e, i)} /></p>))
                                (background.map((ele, i) => <div><p className="mt-2 mr-4" onClick={() => window.open(ele.name ? null : `https://storage.googleapis.com/media-content-hungama-com/${ele}`, "_blank")} style={{ cursor: "pointer", display: "inline" }}>{ele.name ? ele.name : ele}</p><CancelIcon onClick={(e) => deleteBackground(e, i, ele)} /></div>))
                                : null}
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"
                            >
                                V3
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={(e)=>setV3([...v3,...e.target.files])}
                                />
                            </Button>
                            {v3.length > 0 ?
                                // (v3.map((ele, i) => <p className="mt-2 mr-4">{ele.name} <CancelIcon onClick={(e) => deleteV3(e, i)} /></p>))
                                (v3.map((ele, i) => <div><p className="mt-2 mr-4" onClick={() => window.open(ele.name ? null : `https://storage.googleapis.com/media-content-hungama-com/${ele}`, "_blank")} style={{ cursor: "pointer", display: "inline" }}>{ele.name ? ele.name : ele}</p><CancelIcon onClick={(e) => deleteV3(e, i, ele)} /></div>))
                                : null}
                        </Grid>
                        <Grid item md={12} sm={12} style={{ marginTop: "30px" }}>
                            <h5>Bucket Query</h5>
                            <BucketQuery
                                option1={option1}
                                option2={option2}
                                option3={option3}
                                text={text}
                                query={query}
                                andOr={andOr}
                                optionOneHandleChange={optionOneHandleChange}
                                optionTwoHandleChange={optionTwoHandleChange}
                                optionThreeHandleChange={optionThreeHandleChange}
                                onTextChange={onTextChange}
                                onSubmitquery={onSubmitquery}
                                onAndOrChange={onAndOrChange}
                                ondeleteQuery={ondeleteQuery}
                                tags={tags}
                                content_type={localStorage.getItem("userRole") != "SuperAdmin" ? content_type : game_content_type}
                                fields={fields}
                                operators={operators}
                                onSerachQuery={onSerachQuery}
                                textRef={textRef}
                                queryContentType={queryContentType}

                            />
                        </Grid>
                        <Grid container alignItems="center" className={classes.root}>
                            <Grid item md={5}>
                                <h4>Selected Content Data</h4>
                                {left('Choices', leftData)}
                            </Grid>
                            <Grid item md={1}>
                                <Grid container direction="column" alignItems="center">
                                    <Button
                                        style={{ margin: "5px" }}
                                        variant="outlined"
                                        size="small"
                                        className={classes.button}
                                        onClick={handleCheckedRight}
                                        disabled={leftChecked.length === 0}
                                        aria-label="move selected right"
                                    >
                                        &gt;
                                    </Button>
                                    <Button
                                        style={{ margin: "5px" }}

                                        variant="outlined"
                                        size="small"
                                        className={classes.button}
                                        onClick={handleCheckedLeft}
                                        disabled={rightChecked.length === 0}
                                        aria-label="move selected left"
                                    >
                                        &lt;
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item md={5}><h4>Content Data</h4>{right('Choices', queryResult)}


                            </Grid>
                        </Grid>
                        <Grid item md={12} className="mt-4">
                            <Button className="mr-2"  variant="contained" color="primary" onClick={onSubmit}>Submit</Button>
                            <Button variant="contained" color="secondary" >Clear</Button>

                        </Grid>

                    </Grid>

                </div>

            </Paper>
        </Fragment>
    )
}
