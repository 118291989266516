import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CenterFocusStrong } from '@material-ui/icons';

const drawerWidth = 240;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 210,
    },
    '& > *': {
      borderBottom: 'unset',
    
    },
    margin: 'auto',
    
  },
  bucketName:{
    
    marginTop: "6px",
    minWidth: "60%",
  },
  bucketqueryName:{
    
    margin: theme.spacing(1),
    minWidth: "20%",
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth
    }

  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "60%",
    justifyContent:"left"
    
  },
  formControlBucket: {
    margin: theme.spacing(1),
    minWidth: "40%",
    justifyContent:"left"
    
  },
  bucketform:{
    justifyContent:"left",
    alignContent:"left"
  },
  formModal: {
    margin: theme.spacing(1),
    minWidth: 120,
    justifyContent:"left",
    alignContent:"left"
    
  },
  modalAdd:{
    marginTop:"18px",
    margin: theme.spacing(1), 
  },
  lables:{
    justifyContent:"left",
    display :"inline",
    width:"10%"
  },
  
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  upload:{
    marginTop: theme.spacing(3),
    marginleft: theme.spacing(1),
    width:"60%"

  },
  table: {
    minWidth: 650,
  },
  btnedit:{
    color:"#eac839",
    marginRight:"10px",
    cursor:"pointer",
    
},
bucketIcon:{
  color:"blue",
  marginRight:"10px",
  cursor:"pointer",
  
},
btndelete:{
  color:"#ff7272",
  cursor:"pointer",
  
},
dateTime:{
  justifyContent:"center",  
  width:"18%"
},
inputField:{
  width:"60%",
  margin:theme.spacing(1)
},
btngrp:{
  display :"inline",
  margin:"1%"
},
tablestyle:{
  overflow:"sroll",
  width:1500
},
liststyle:{
  width: '100%',
  maxWidth: 460,
  justifyContent:"center",
  alignContent:"center"
},
dialogStyle:{
  width:"100%"
},
cardHeader: {
  padding: theme.spacing(1, 2),
},
list: {
  width: "auto",
  height: 330,
  backgroundColor: theme.palette.background.paper,
  overflow: 'auto',
},
Card:{
  maxWidth:"500px"
},
search: {
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#E5E4E2",
  '&:hover': {
    backgroundColor: "#D3D3D3",
  },
  marginLeft: 0,
  width: '50%',
  justifySelf:"flex-end",
  [theme.breakpoints.up('sm')]: {
    marginLeft: "590px",
    justifySelf:"flex-end",
    width: 'auto',
  },
},
searchIcon: {
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
},
inputRoot: {
  color: 'inherit',
},
inputInput: {
  padding: theme.spacing(1, 1, 1, 0),
  // vertical padding + font size from searchIcon 
  paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
  transition: theme.transitions.create('width'),
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: '12ch',
    '&:focus': {
      width: '20ch',
    },
  },
},
contentSearch: {
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#E5E4E2",
  '&:hover': {
    backgroundColor: "#D3D3D3",
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: "0px",
    width: 'auto',
  },
},

Collectionsearch: {
  position: 'relative',
  justifyContent:"center",
  alignContent:"center",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#E5E4E2",
  '&:hover': {
    backgroundColor: "#D3D3D3",
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    // marginLeft: "310px",
    width: 'auto',
    height:"45px"
  },
},

CollectioninputInput: {
  padding: theme.spacing(1, 1, 1, 0),
  // vertical padding + font size from searchIcon 
  paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
  transition: theme.transitions.create('width'),
  width: '400%',
  [theme.breakpoints.up('sm')]: {
    width: '40ch',
  },
},
tabbar:{
  flexGrow: 1,
  width: '100%',
  backgroundColor: theme.palette.background.paper,
},
backdrop: {
  zIndex: theme.zIndex.drawer + 3,
  color: '#fff',
},
greenDotStyle : {
  '--sds-c-icon-color-foreground-default': 'green'
},
redDotStyle : {
  '--sds-c-icon-color-foreground-default': 'red'
}
}));